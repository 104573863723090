import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import homeImg from "../images/listening-to-the-sea-shell.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className="text-4xl lg:text-5xl text-center mb-10">
      Piano Studio of Lenka Lazorikova
    </h1>
    <div className="flex flex-col md:flex-row items-center md:items-start">
      <div className="px-10 flex-shrink-0 pb-10">
        <img
          src={homeImg}
          alt=""
          className="p-1 bg-white rounded border border-gray-light w-48"
        />
      </div>
      <ul className="list-musical-notes leading-loose pb-10 lg:pl-10">
        <li>
          I am offering <b>private piano lessons</b> for children and adults of
          all levels and ages.
        </li>
        <li>
          In addition to <b>traditional teaching</b> method I also provide{" "}
          <b>Suzuki instruction</b>.
        </li>
        <li>
          I will <b>carefully choose materials</b> appropriate to each student,
          based on their age, ability and learning style.
        </li>
        <li>
          Students learn <b>notes reading</b> as soon as they are ready
          (children are often ready when they are reading words.)
        </li>
      </ul>
    </div>
    <p className="text-justify leading-loose">
      The minimum age for traditional study for piano is 6 years old; for Suzuki
      4 years old. Students are required to own or have access to a piano for
      practice during the week in order to take private lessons. Those who do
      not own a piano should investigate local music stores to rent or purchase
      an instrument. Beginning piano students may use an electronic keyboard. I
      recommend a touch-sensitive keyboard with at least 66 keys. The electronic
      keyboard should also have a stand and a music rack. Parents/students who
      use an electronic keyboard for beginning lessons should realize that this
      is a temporary solution to the need for an acoustic piano.
    </p>
  </Layout>
)

export default IndexPage
